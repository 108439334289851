@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';

.layout {
  $breakpoint: 950px; // NOTE: Must be the same breakpoint as menu.scss

  @media (min-width: $breakpoint) {
    height: 100%;
  }

  &__messages {
    position: fixed;
    max-width: 800px;
    width: 100%;
    top: spacing.$xl;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 spacing.$xs;
    z-index: 200;

    @media (min-width: $breakpoint) {
      left: calc(
        50% + 125px
      ); // NOTE: The pixels substracted must be half of the desktop menu width in menu.scss
    }

    @media (max-width: 1050px) {
      // NOTE: Custom width to avoid the fixed message being hidden on certain breakpoints
      max-width: 600px;
    }
  }

  &__main {
    padding: spacing.$xl spacing.$xs;
    margin-bottom: spacing.$xxl;

    @media (min-width: 600px) {
      padding-left: spacing.$m;
      padding-right: spacing.$m;
    }

    @media (min-width: $breakpoint) {
      padding-left: spacing.$l;
      padding-right: spacing.$l;
      margin-left: 250px; // NOTE: Must be the same the width of the desktop menu in menu.scss
    }

    @media (min-width: 1200px) {
      padding-left: spacing.$xxl;
      padding-right: spacing.$xxl;
      margin-left: 250px; // NOTE: Must be the same the width of the desktop menu in menu.scss
    }
  }

  &__banner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: spacing.$xxs;
    background-color: light.$ge-signal-warning;
    padding: spacing.$s;
  }
}
