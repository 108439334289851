@use 'sats-ui-lib/tokens/light';

.logo {
  &--dark {
    color: light.$on-background-primary-default;
  }

  &--light {
    color: light.$on-fixed-background-primary-default;
  }
}
