@use 'sats-ui-lib/tokens/light';

html {
  box-sizing: border-box;
  color: light.$on-background-primary-default;
  font-family: Inter, sans-serif; // NOTE: `@use "sats-ui-lib/tokens/fonts"` doesn't work (font file reference errors), but we know that the font name is "Inter".
  font-size: 18px;
  overflow-y: scroll;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus {
  outline: 4px solid currentColor;
  outline-offset: 2px;
}

.mouse-user :focus {
  outline: none !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  background-color: light.$background-primary-default;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a,
button {
  color: inherit;
}

.mouse-user * {
  outline: none;
}
