@use 'sats-ui-lib/tokens/spacing';

.spinner {
  display: flex;
  justify-content: center;
  margin: spacing.$l 0;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__icon {
    animation: spin 2s linear infinite;
  }

  &--inline {
    display: inline-flex;
  }

  &--overlay {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.7);

    .spinner__icon {
      position: sticky;
      top: 50%;
      margin: 0 auto;
    }
  }
}
