@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/spacing';

.rich-text {
  > * + * {
    margin-top: spacing.$m;
  }

  ul {
    margin-top: spacing.$l;
    list-style: disc;

    &:first-child {
      margin-top: 0;
    }
  }
}
