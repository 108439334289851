@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';

.menu {
  $breakpoint: 950px; // NOTE: Must be the same breakpoint as layout

  background-color: light.$fixed-background-secondary-default;
  color: light.$on-fixed-background-primary-default;
  z-index: 99;

  overflow-y: auto;

  &__group-title {
    color: light.$on-fixed-background-primary-alternate;
    margin-bottom: spacing.$s;
  }

  &__group-item {
    display: flex;
    align-items: flex-start;
    padding: spacing.$s;
    border-radius: spacing.$s;
    text-decoration: none;

    &:hover {
      background-color: light.$fixed-background-secondary-hover;
    }

    &--active {
      background-color: light.$fixed-background-secondary-selected;
    }
  }

  &__group-link {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__group-icon {
    margin-right: spacing.$s;
  }

  &__divider {
    border-bottom: 1px solid light.$ge-divider-default;
    width: 100%;
    margin-top: spacing.$s;
    margin-bottom: spacing.$s;
  }

  &__desktop {
    display: none;
    @media (min-width: $breakpoint) {
      position: relative;
      display: block;

      height: 100%;
      width: 250px;
      position: fixed;
      top: 0;
      left: 0;

      padding: spacing.$s;
    }
  }

  &__desktop-logo {
    display: flex;
    justify-content: center;
    padding: spacing.$l 0;
  }

  &__mobile {
    &--open {
      position: fixed;
      height: 100%;
      width: 100%;
    }

    @media (min-width: $breakpoint) {
      display: none;
    }
  }

  &__mobile-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing.$m;
    height: 58px;
  }

  &__mobile-content {
    padding: spacing.$m;
  }

  &__toggle {
    position: absolute;
    top: 6px;
    left: 6px;
  }

  &__footer {
    margin-top: spacing.$xl;
  }

  &__footer-link {
    display: block;
    margin-bottom: spacing.$s;
    text-align: center;
    color: light.$on-signal-surface-featured-alternate;
  }

  &__action-box {
    display: flex;
    padding: spacing.$s spacing.$xs;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: spacing.$xs;
    border-radius: spacing.$s;
    background-color: light.$fixed-surface-secondary-default;
    margin-bottom: spacing.$l;
    text-decoration: none;

    &:hover {
      background: light.$fixed-surface-secondary-hover;
    }
  }
}
